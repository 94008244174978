<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>

<a
  *ngIf="link"
  [class]="'custom-button ' + buttonStyle"
  [class.disabled]="disabled"
  [class.thin]="thin"
  [class.tiny]="tiny"
  [href]="link"
  [target]="openInNewTab ? '_blank' : '_self'">
  <span class="button-text">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </span>
</a>

<a
  *ngIf="!link"
  [class]="'custom-button ' + buttonStyle"
  [class.disabled]="disabled"
  [class.thin]="thin"
  [class.tiny]="tiny">
  <span class="button-text">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </span>
</a>
