import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

type ButtonStyle = 'primary' | 'primaryDark' | 'secondary' | 'addMore' | 'red';

@Component({
  selector: 'cl-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() buttonStyle: ButtonStyle = 'primary';
  @Input() @HostBinding('class.disabled') disabled = false;
  @Input() thin = false;
  @Input() tiny = false;
  @Input() link: string;
  @Input() openInNewTab = false;
}
